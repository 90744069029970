//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import Web3ModalVue from "web3modal-vue";
import WalletConnectProvider from "@walletconnect/web3-provider";

  import Avatar from "./avatar";

  export default {
    components: {
      Avatar, Web3ModalVue
    },
    data() {
      return {theme: "dark"};
    },
    created() {
      if (process.client &&
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.theme = "dark";
      }
    },

    mounted() {
      this.initClientOnlyComp();
    },

    computed: {
      web3ProviderOptions(){
        return this.$web3ProviderOptions;
      },
      web3() {
        return this.$store.state.web3;
      },
      art() {
        return this.$store.getters["token/myTokens"];
      },
    },

    methods: {
      initClientOnlyComp(count = 10) {
        this.$nextTick(() => {
          if (this.$refs.web3modal) {
            this.$setWeb3Modal(this.$refs.web3modal);
            this.$store.commit("web3/initWeb3Modal");
          } else if (count > 0) {
            this.initClientOnlyComp(count - 1);
          }
        });
      },

      shortAddress(address) {
        return (
          address.substr(0, 4) + " … " + address.substr(address.length - 3, 3)
        );
      },
    },
  };
