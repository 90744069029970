//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const BigNumber = require('bignumber.js');
export default {
    props: ['visible'],

    data() {
        return {
            count: 1,
            errorText: '',
            successText: '',
            buyedTotalPrice: 0,
            buyed: false,
            waitingForBlockchain: false,
            tx: '',
            fewEthDialog: false,
            walletAmonut: 0
        };
    },
    methods: {
        previewTokens() {},

        async buy() {
            this.waitingForBlockchain = true;
            let price = this.toWei(this.price).toString();
            let balance = await this.$library().getBalance(this.web3.account);
            if(this.toWei(this.price) > parseFloat(balance.toString())){
                this.walletAmonut = balance / 10 ** 18;
                this.fewEthDialog = true;
                this.waitingForBlockchain = false;
                return;
            }

            this.$web3Call(false, 'beforeBuyTokens', price)
            .then(info => {
                
                if (!info[0]) {
                    this.errorText = '';
                    this.$web3Send(false, 'buyTokens',
                    {
                        from: this.web3.account,
                        value: price
                    }).then(a => {
                        this.waitingForBlockchain = false;
                        this.buyedTotalPrice = this.price;
                        this.buyed = true;
                        this.tx = a.transactionHash;
                        this.successText =
                        "<a target='_blank' href='"+this.link+"'>" + this.link + "</a>";
                    }).catch(e => {
                        this.waitingForBlockchain = false;
                    });
                } else {
                    console.log(info,price);
                    this.waitingForBlockchain = false;
                    this.errorText = info[1];
                }
            })
            .catch(e => {
                console.log("ae", e);
                this.waitingForBlockchain = false;
            });
        },

        roundToWei(number) {
            return parseFloat(number.toFixed(18));
        },
        toWei: function(eth) {
            return new BigNumber(eth).multipliedBy(10**18);
        },
    },

    watch: {
        visible(val) {
            if (val == true) {
                this.buyed = false;
                this.count = 1;
            }
        }
    },
    computed: {
        soldOut() {
            if (this.token.lastTokenIndex + 1 === 10927) {
                return true;
            } else {
                return false;
            }
        },

        token() {
            return this.$store.state.token;
        },
        
        web3() {
            return this.$store.state.web3;
        },

        link() {
            return this.$etherscanUrl(this.tx);
        },

        price() {
            if (this.token.phases.length == 0) return 0;
            return this.roundToWei((this.token.phases[this.token.actualPhase].prize * this.count) / 10 ** 18);
        },

        maxTokens() {
            if (this.token.phases.length == 0 || this.token.actualPhase > 18) return 0;
            return this.token.buyLog[this.token.actualPhase].t - this.token.buyLog[this.token.actualPhase].b.length;
        }
    },
};
