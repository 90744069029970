import { render, staticRenderFns } from "./buy.vue?vue&type=template&id=e772d92c&scoped=true&"
import script from "./buy.vue?vue&type=script&lang=js&"
export * from "./buy.vue?vue&type=script&lang=js&"
import style0 from "./buy.vue?vue&type=style&index=0&id=e772d92c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e772d92c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NumberInput: require('/home/martin/web/decentral-art/components/number-input.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VCardActions,VCardText,VCardTitle,VDialog,VSpacer})
