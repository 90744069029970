//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        small: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {};
    },

    methods: {},

    computed: {
        token() {
            return this.$store.state.token;
        }
    }
};
