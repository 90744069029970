//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {

  props: {
    light: {
      type: Boolean,
      default: false
    }
  },

    data() {
        return {
            windowWidth: 0,
            previewId: false,
            show: false,
            selectedId: null,
            mymap: null,
            start: { lat: 0, lng: 0 },
            end: { lat: -118, lng: 236 },
            bounds: null
        };
    },

    destroyed() {
        window.removeEventListener('resize', this.screenWidth);
    },

    mounted() {

        window.addEventListener('resize', this.screenWidth);

        this.windowWidth = window.innerWidth;
        this.bounds = new L.LatLngBounds(new L.LatLng(this.start.lat, this.start.lng), new L.LatLng(this.end.lat, this.end.lng));

        const { bounds } = this;
        this.mymap = L.map(this.$refs.imageMap, {
            crs: L.CRS.Simple,
            maxBounds: bounds,
            maxBoundsViscosity: 0.5,
            zoomDelta: 0.5,
            zoomSnap: 0.01,
            attributionControl: false,
            zoomControl: this.light ? false : true
        }).setView(bounds.getCenter(), 0);

        this.setMinZoom();

        this.tileLayer = L.tileLayer('https://decentral-art.com/art-parts/{z}/{x}/{y}.jpg', {
            attribution: '',
            maxZoom: 9,
            bounds: bounds,
            tileSize: 256,
            noWrap: true
        }).addTo(this.mymap);

        this.mymap.fitBounds(bounds);

        //
        // var latlngs = L.rectangle(bounds).getLatLngs();
        // L.polyline(latlngs[0].concat(latlngs[0][0])).addTo(this.mymap);
        //

        this.layerGroup = L.featureGroup([]);
        this.layerGroup.on('click', e => {
            this.showPreview(e.layer.options.x, e.layer.options.y);
        });

        this.mymap.on('zoomend', () => {
            var z = this.mymap.getZoom();

            if (z > 4) {
                return this.layerGroup.addTo(this.mymap);
            }

            return this.layerGroup.removeFrom(this.mymap);
        });
    },

    watch: {
        windowWidth(width) {
            this.setMinZoom();

            this.bounds = new L.LatLngBounds(
                new L.LatLng(this.start.lat, this.start.lng),
                new L.LatLng(this.end.lat, this.end.lng)
            );

            this.mymap.setView(this.bounds.getCenter(), 0);
        },

        'token.image'() {
            this.tileLayer.setUrl('https://decentral-art.com/art-parts/{z}/{x}/{y}.jpg?t=' + Date.now());
        },
        'token.lastTokenIndex'() {
            this.layerGroup.clearLayers();
            let dLat = 1235 / 2 ** 9;
            let dLng = 543 / 2 ** 9;
            if (this.token.lastTokenIndex > -1) {
                for (var i = 0; i <= this.token.lastTokenIndex; i++) {
                    let t = this.$DB[i];
                    this.layerGroup.addLayer(
                        L.rectangle(
                            [
                                [this.start.lat - dLat * t.y, this.start.lng + dLng + t.x * dLng],
                                [this.start.lat - dLat - dLat * t.y, this.start.lng + t.x * dLng]
                            ],
                            { color: '#d4af37de', fillOpacity: 0, opacity: 1, weight: 1, token: i, x: t.x, y: t.y }
                        )
                    );
                }
            }
        }
    },

    computed: {
        soldOut() {
            if (this.token.lastTokenIndex + 1 === 10927) {
                return true;
            } else {
                return false;
            }
        },

        token() {
            return this.$store.state.token;
        },

        buyed() {
            let mined = [];
            if (this.token.totalBuyedPrice > 0) {
                for (let i = 0; i <= this.token.lastTokenIndex; i++) {
                    mined.push(this.$DB[i].x + '-' + this.$DB[i].y);
                }
            }
            return mined;
        }
    },

    methods: {
        screenWidth(e) {
            this.windowWidth = e.currentTarget.innerWidth;
        },

        setMinZoom() {
            const { windowWidth: width } = this;
            if (width >= 1264) {
                this.mymap.options.minZoom = 2;
            } else if (width < 1264 && width >= 960) {
                this.mymap.options.minZoom = 1.7;
            } else if (width < 820 && width >= 720) {
                this.mymap.options.minZoom = 1.5;
            } else if (width < 720 && width >= 638) {
                this.mymap.options.minZoom = 1.3;
            } else if (width < 638 && width >= 524) {
                this.mymap.options.minZoom = 1;
            } else if (width < 524 && width >= 435) {
                this.mymap.options.minZoom = 0.7;
            } else if (width < 435 && width >= 338) {
                this.mymap.options.minZoom = 0.4;
            } else if (width < 338 && width >= 320) {
                this.mymap.options.minZoom = 0;
            }
        },

        close() {
            this.show = false;
            this.selectedId = null;
            this.previewId = null;
        },

        showPreview(x, y, e) {
            if (this.isBuyed(x, y)) {
                this.show = true;
                this.selectedId = this.tokenId(x, y);
            }
        },

        tokenId(x, y) {
            return this.buyed.indexOf(x + '-' + y);
        },

        isBuyed(x, y) {
            return this.buyed.indexOf(x + '-' + y) != -1;
        }
    }
};
