//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const isNaN = Number.isNaN || window.isNaN;
const REGEXP_NUMBER = /^-?(?:\d+|\d+\.\d+|\.\d+)(?:[eE][-+]?\d+)?$/;
const REGEXP_DECIMALS = /\.\d*(?:0|9){10}\d*$/;
const normalizeDecimalNumber = (value, times = 100000000000) => (
  REGEXP_DECIMALS.test(value) ? (Math.round(value * times) / times) : value
);
export default {
  name: 'NumberInput',
  model: {
    event: 'change',
  },
  props: {
    attrs: {
      type: Object,
      default: undefined,
    },
    hideDetails: Boolean,
    center: Boolean,
    dense: Boolean,
    right: Boolean,
    controls: Boolean,
    disabled: Boolean,
    inputtable: {
      type: Boolean,
      default: true,
    },
    inline: Boolean,
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    name: {
      type: String,
      default: undefined,
    },
    placeholder: {
      type: String,
      default: undefined,
    },
    label: {
      type: String,
      default: undefined,
    },
    readonly: Boolean,
    rounded: Boolean,
    size: {
      type: String,
      default: undefined,
    },
    message: {
      type: String,
      default: undefined,
    },
    step: {
      type: Number,
      default: 1,
    },
    precision: {
      type: Number,
      default: null,
    },
    value: {
      type: Number,
      default: NaN,
    },
  },
  data() {
    return {
      currentValue: NaN,
    };
  },
  computed: {
    /**
     * Indicate if the value is increasable.
     * @returns {boolean} Return `true` if it is decreasable, else `false`.
     */
    increasable() {
      const num = this.currentValue;
      return isNaN(num) || num < this.max;
    },
    /**
     * Indicate if the value is decreasable.
     * @returns {boolean} Return `true` if it is decreasable, else `false`.
     */
    decreasable() {
      const num = this.currentValue;
      return isNaN(num) || num > this.min;
    },
    /**
     * Filter listeners
     * @returns {Object} Return filtered listeners.
     */
    listeners() {
      const listeners = { ...this.$listeners
      };
      delete listeners.change;
      return listeners;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(newValue, oldValue) {
        if (
          // Avoid triggering change event when created
          !(isNaN(newValue) && typeof oldValue === 'undefined')
          // Avoid infinite loop
          &&
          newValue !== this.currentValue
        ) {
          this.setValue(newValue);
        }
      },
    },
  },
  methods: {
    /**
     * Change event handler.
     * @param {string} value - The new value.
     */
    change(value) {
      this.setValue(Math.min(this.max, Math.max(this.min, value)));
    },
    /**
     * Paste event handler.
     * @param {Event} event - Event object.
     */
    paste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      if (clipboardData && !REGEXP_NUMBER.test(clipboardData.getData('text'))) {
        event.preventDefault();
      }
    },
    /**
     * Decrease the value.
     */
    decrease() {
      if (this.decreasable) {
        let {
          currentValue
        } = this;
        if (isNaN(currentValue)) {
          currentValue = 0;
        }
        this.setValue(Math.min(this.max, Math.max(
          this.min,
          normalizeDecimalNumber(currentValue - this.step),
        )));
      }
    },
    /**
     * Increase the value.
     */
    increase() {
      if (this.increasable) {
        let {
          currentValue
        } = this;
        if (isNaN(currentValue)) {
          currentValue = 0;
        }
        this.setValue(Math.min(this.max, Math.max(
          this.min,
          normalizeDecimalNumber(currentValue + this.step),
        )));
      }
    },
    /**
     * Set new value and dispatch change event.
     * @param {number} value - The new value to set.
     */
    setValue(value) {
      const oldValue = this.currentValue;

      let newValue = value;
      

      if (this.min <= this.max) {
        newValue = Math.min(this.max, Math.max(this.min, newValue));
      }
      let c = parseInt(newValue / this.step) * this.step;
      if (newValue % this.step > this.step/2)
        c += this.step;
      
      if(this.rounded){
        if(this.precision != null){
          c = Math.round(value*10**this.precision)/10**this.precision;
          c = parseFloat(c.toFixed(this.precision));
        } else {
          c = Math.round(value);
        }
      } 
     // console.log("sa",newValue,c);
      this.currentValue = c;
      this.$emit('change', c);
    },
  },
};

