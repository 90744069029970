//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
    };
  },

  methods: {
    close() {
      this.$emit('close');
    }
  },
  mounted() {
  }
}
