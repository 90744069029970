//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { numberWithSpaces } from '@/helpers/numberWithSpaces';
export default {
    props: {
        showImage: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            windowWidth: 900,
            showWideoPlayer: false
        };
    },

    computed: {
        token() {
            return this.$store.state.token;
        },

        isLastTokenBought() {
            return this.token.lastTokenIndex + 1 === 10927;
        },

        totalWinner() {
            let obj = this.token.percentWinners[10927];
            if (typeof obj == 'undefined') {
                return false;
            } else {
                return this.token.indexes[obj.winner];
            }
        }
    },

    methods: {
        toggleVideoPlayer(){
            this.showWideoPlayer = !this.showWideoPlayer;
        },

        toUSD(usd) {
            return numberWithSpaces(usd);
        }
    }
};
