//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },

        id: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            pipHover: false
        };
    },

    computed: {
        token() {
            return this.$store.state.token;
        }
    },

    methods: {
        imageSrc(id) {
            if(!this.$DB[id]) return "";
            if(this.token.vipIndexes.indexOf(id) == -1){
                return "https://api.decentral-art.com/token-preview/"+id+"/ordinary";
            }
            return "https://api.decentral-art.com/token-preview/"+id+"/vip";
        },
    },

    mounted() {},

};
