import { render, staticRenderFns } from "./index.vue?vue&type=template&id=60ebde3c&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeaderMenu: require('/home/martin/web/decentral-art/components/header-menu.vue').default,Wallet: require('/home/martin/web/decentral-art/components/wallet.vue').default,HeaderSmallMenu: require('/home/martin/web/decentral-art/components/header-small-menu.vue').default,SectionHeader: require('/home/martin/web/decentral-art/components/section-header.vue').default,Counters: require('/home/martin/web/decentral-art/components/counters.vue').default,ArtImageMap: require('/home/martin/web/decentral-art/components/art-image-map.vue').default,ContractSatus: require('/home/martin/web/decentral-art/components/contract-satus.vue').default,Buy: require('/home/martin/web/decentral-art/components/buy.vue').default,SectionFooter: require('/home/martin/web/decentral-art/components/section-footer.vue').default})


/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VBtn,VCard,VContainer,VDialog,VIcon,VMenu,VOverlay,VProgressLinear})
