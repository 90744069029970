//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      blockchain: null,
      buyDialog: false,
      progressValue: 20,
      connectIndex: 0,
      showPreviewDialog: 0
    };
  },

  head() {
    return {
      title: "Decentral-art",
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'A virtual place for digital art resources. Buy and sell NFT pieces of art on Decentral-art. Win prizes for participation, and airdrops…'
        }
      ]
    }
  },

  mounted() {
    let interval = setInterval(() => {
      this.progressValue += 20;
      if (this.progressValue >= 100) {
        clearInterval(interval);
      }
    }, 100);
  },

  methods: {
    blockchainEvent(error, event) {
      //console.log(event);
      if (error == null) this.blockchain.loadContractData();
    },
    showBuyDialog() {
      this.buyDialog = !this.buyDialog;
    },
    metamaskUpdated(s) {
      //console.log(s);
    }
  },

  computed: {
    showOverlay() {
      return this.progressValue < 100;
    }
  }
};
